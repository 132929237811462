/* Site-Wide Styles -- USE SPARINGLY */

@import-normalize; /* CSS Reset/Normalizer */
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
html {
  height: 100%;
  min-height: 100vh;
}

body {
  font-family: 'Work Sans', 'Courier New', sans-serif;
  min-height: 100%;
  height: 100%;
  margin: 0px;
}
#app {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  overflow: hidden; /*makes the body non-scrollable (we will add scrolling to the sidebar and main content containers)*/
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
}

h2 {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
}

h3 {
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
}

h4 {
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
}

h5 {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
}

h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

caption {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
}

p {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

button,
input {
  border: 0;
}

textarea {
  font-family: 'Work Sans';
}

input:-moz-focusring {
  outline: none;
}

div:-moz-focusring {
  outline: none;
}

a:-moz-focusring {
  outline: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

legend {
  padding: 0;
  display: table;
}
fieldset {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;
}
body:not(:-moz-handler-blocked) fieldset {
  display: table-cell;
}

/* Required because the stripe element is not our and isn't stylable */
.StripeElement{
  width: 100%
}
/* this was necessary because styled-components wasn't targeting the modified classes appropriately */
/* if necessary, we can move this to a separate stylesheet and import to each component that requries a collapsible */

.Collapsible__contentOuter {
  padding: 1px;
}

.contentOuter__no_padding {
  padding: 0px;
}

.MuiDialogContent-root {
  outline: none;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:focus,
input:-internal-autofill-selected {
border: none;
outline: none;
outline-offset: none;
background-color: none;
color: none;
font-size: inherit;
font-family: inherit;
font-weight: inherit;
-webkit-text-fill-color: inherit;
-webkit-box-shadow: none;
} */

.MuiBottomNavigationAction-label {
  font-family: 'Work Sans';
  font-size: 11px !important;
  letter-spacing: 0;
  line-height: 14px;
}
.MuiBottomNavigationAction-root.Mui-selected {
  color: #282828 !important;
}
.MuiTabs-indicator {
  background-color: #ff6969;
  height: 4px;
}
.MuiTab-textColorSecondary.Mui-selected {
  color: #ff6969;
}
.MuiBottomNavigationAction-root {
  min-width: 0;
}

/* hide lastpass icons */
#__lpform_currentPassword, #__lpform_currentPassword_icon, #__lpform_currentPassword_numspan {
  display: none;
  visibility: hidden;
}
